<template>
  <div class="two-col">
    <div class="row flex" v-for="(item, index) in dataList" :key="index">
      <div class="left flex-1">
        <img :src="item.bgImg" v-if="index % 2 === 0" class="bg-img" />
        <div class="content odd-right flex flex-col justify-center" v-else>
          <img :src="item.icon" v-if="item.icon" class="icon" />
          <p class="one-title">{{ item.oneTitle || '' }}</p>
          <p class="two-title">{{ item.twoTitle || '' }}</p>
          <p class="title" v-if="!item.children && !item.oneTitle">{{ item.title }}</p>
          <p :class="['desc', !item.children && !item.oneTitle && 'mt-20']" v-if="!item.children">{{ item.desc }}</p>
          <div class="card" v-for="(_item, _index) in item.children" :key="_index">
            <p class="title">{{ _item.title }}</p>
            <div class="flex align-center">
              <p class="card-content" v-for="(v, k) in _item.items" :key="k">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="right flex-1">
        <img :src="item.bgImg" v-if="index % 2 === 1" class="bg-img" />
        <div class="content odd-right flex flex-col justify-center" v-else>
          <img :src="item.icon" v-if="item.icon" class="icon" />
          <p class="one-title">{{ item.oneTitle || '' }}</p>
          <p class="two-title">{{ item.twoTitle || '' }}</p>
          <p class="title" v-if="!item.children && !item.oneTitle">{{ item.title }}</p>
          <p :class="['desc', !item.children && !item.oneTitle && 'mt-20']" v-if="!item.children">{{ item.desc }}</p>
          <div class="card" v-for="(_item, _index) in item.children" :key="_index">
            <p class="title">{{ _item.title }}</p>
            <div class="flex align-center">
              <p class="card-content" v-for="(v, k) in _item.items" :key="k">
                {{ v.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/** 两列多行组件 */
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less" scoped>
.two-col {
  .row {
    height: 300px;
    .left,
    .right {
      overflow: hidden;
    }
    .bg-img {
      height: 100%;
      width: 100%;
      transform: scale(1);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
      cursor: pointer;
    }
    .bg-img:hover {
      transform: scale(1.2);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
    }
    .odd-right {
      background: rgba(33, 67, 174, 1);
    }
    .content {
      height: 100%;
      width: 100%;
      background: rgba(33, 67, 174, 1);
      padding: 48px 80px;
      .icon {
        max-width: 48px;
        max-height: 48px;
      }
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        position: relative;
      }
      .title::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(61, 238, 228, 1);
        position: absolute;
        left: -20px;
        top: 8px;
      }
      .one-title {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        margin: 5px 0;
      }
      .two-title {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        margin: 5px 0;
      }
      .desc {
        color: rgba(255, 255, 255, 0.8);
        font-size: 12px;
        width: 312px;
        line-height: 30px;
      }
      .card-content {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        padding: 2px 8px;
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 15px;
        margin: 15px 20px 15px 0;
      }
    }
  }
}
</style>

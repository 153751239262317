<template>
  <div class="local-life">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">一站式对接，直连1000+品牌供应商 海量商品自由选</h1>
        <div class="banner-two flex">
          <button class="btn">全品类</button>
          <button class="btn">专业运维保障</button>
          <button class="btn">平台优惠资源</button>
        </div>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 数字化商品供应链 -->
    <div class="supply-chain">
      <div class="container mx-auto">
        <h2 class="three-leave-title">本地生活</h2>
      </div>
      <div class="back-box">
        <img src="@/assets/img/local/loacl.jpg" class="bg-img" />
        <div class="content">
          <div class="container mx-auto flex flex-col">
            <div class="card flex-1">
              <div class="fore-leave-title">本地</div>
              <div class="grid grid-cols-4 grid-rows-1 gap-4">
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-one.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">吃</div>
                  <div class="two-title">十万户商家，百万菜品</div>
                  <div class="two-title">中餐、西餐、日式韩式料理各种美味</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-two.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">喝</div>
                  <div class="two-title">各种美酒饮品应有尽有</div>
                  <div class="two-title">主流品牌深度合作，10万款SKU</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-three.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">玩</div>
                  <div class="two-title">十万+品牌直连</div>
                  <div class="two-title">直面用户流量痛点</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-fore.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">乐</div>
                  <div class="two-title">情侣约会、逛街游玩、朋友聚会</div>
                  <div class="two-title">满足各种场景，360°覆盖消费场景</div>
                </div>
              </div>
            </div>
            <div class="card flex-1">
              <div class="fore-leave-title">生活</div>
              <div class="grid grid-cols-4 grid-rows-1 gap-4">
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-five.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">衣</div>
                  <div class="two-title">100%正品保证</div>
                  <div class="two-title">最新潮流单品快速抢占流量入口</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-six.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">食</div>
                  <div class="two-title">100%美味美食</div>
                  <div class="two-title">美味实惠，缤纷呈现</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-eight.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">住</div>
                  <div class="two-title">100%宾至如归</div>
                  <div class="two-title">用优质商品快速实现业务增长</div>
                </div>
                <div class="card-content flex flex-col justify-center align-center">
                  <div class="img-box flex justify-center align-center">
                    <img src="@/assets/img/local/local-icon-seven.png" class="icon-img" />
                  </div>
                  <div class="line"></div>
                  <div class="one-title">行</div>
                  <div class="two-title">100%安全放心</div>
                  <div class="two-title">适用于用户引流、增强粘性等多种场景</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作客户 -->
    <div class="cooperative-customers container mx-auto">
      <div class="container mx-auto">
        <h2 class="three-leave-title">合作客户</h2>
      </div>
      <HomeBrandSwiper />
    </div>
    <!-- 高效专业运维服务保障 -->
    <div class="supply-chain">
      <div class="container mx-auto">
        <h2 class="three-leave-title">海量优质商品</h2>
        <div class="irregular-column container mx-auto">
          <TwoColMulRow :dataList="columnList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import TwoColMulRow from '@/components/common/TwoColMulRow';
import HomeBrandSwiper from '@/components/common/HomeBrandSwiper';

export default {
  components: {
    TwoColMulRow,
    HomeBrandSwiper
  },
  data() {
    return {
      columnList: [
        {
          bgImg: require('@/assets/img/local/1.jpg'),
          title: '',
          desc: '',
          children: [
            {
              title: '商品保障',
              items: [
                {
                  title: '快速响应'
                },
                {
                  title: '极速到户'
                },
                {
                  title: '低延迟'
                }
              ]
            },
            {
              title: '服务保障',
              items: [
                {
                  title: '订单一目了然'
                },
                {
                  title: '账单清晰'
                },
                {
                  title: 'API稳定'
                }
              ]
            },
            {
              title: '售后咨询保障',
              items: [
                {
                  title: '严苛质量管控'
                },
                {
                  title: '活动服务专属管控'
                },
                {
                  title: '回访机制'
                }
              ]
            }
          ]
        },
        {
          bgImg: require('@/assets/img/local/2.jpg'),
          title: '标准采购体系供应商产品溯源',
          desc: '严苛选品，严格保障每一款商品的服务与质量产品比价体系，供应商甄选及考核评级，供应商淘汰名录'
        },
        {
          bgImg: require('@/assets/img/local/3.jpg'),
          title: '标准API对接，海量商品直连自由选',
          desc: '享有商品库内商品、运营以及配送服务，商品自动上下架、订单及快递信息实时同步。企业无需批量采购和库存积压，浪费人力物力，支出只按积分商城的实际兑换来结算。'
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.local-life {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/local/local-bg.png');
    // background-size: 100% 100%;
    background-position: center;
    background-size: center center;
    .title-box {
      padding-top: 68px;
      .banner-one-title {
        width: 640px;
        color: rgba(34, 78, 219, 1);
        letter-spacing: 0.08px;
        font-size: 40px;
        line-height: 50px;
      }
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .banner-two {
        margin: 20px 45px 0 45px;
        .btn {
          padding: 0 10px;
          height: 34px;
          border: 1px solid @lupay-text-color-theme;
          border-radius: 17px;
          margin-right: 10px;
          color: @lupay-text-color-theme;
        }
      }
      .btn-line {
        margin-top: 80px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  .supply-chain {
    margin-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      //   font-weight: 400;
    }
    .back-box {
      position: relative;
      height: 722px;
      margin-top: 50px;
      .bg-img {
        width: 100%;
        height: 100%;
        opacity: 0.2;
      }
      .content {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        .card {
          // margin: 20px 0;
          background-color: @lupay-bg-color-white;
          padding: 49px;
          height: 678px;
        }
        .fore-leave-title {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          position: relative;
          padding-left: 10px;
        }
        .fore-leave-title::before {
          content: '';
          display: block;
          width: 2px;
          height: 18px;
          position: absolute;
          background-color: rgba(34, 78, 219, 1);
          top: 4px;
          left: 0px;
        }
        .card-content {
          .img-box {
            width: 72px;
            height: 72px;
            background: linear-gradient(231.98deg, rgba(176, 198, 245, 1) 0%, rgba(34, 78, 219, 1) 100%);
            box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.2);
            margin-top: 53px;
            border-radius: 50%;
          }
          .icon-img {
            max-width: 40px;
            max-height: 40px;
          }
          .line {
            height: 2px;
            width: 16px;
            background-color: rgba(34, 78, 219, 1);
            margin: 17px 0 24px 0;
          }
          .one-title,
          .two-title {
            color: rgba(0, 0, 0, 0.8);
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
    .irregular-column {
      margin-top: 70px;
    }
  }
  .cooperative-customers {
    margin-top: 50px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      margin-bottom: 50px;
    }
  }
  .fore-card {
    margin-top: 40px;
  }
}
</style>
